import type { ITextProps } from '~/types/SharedFont.types';

export interface ILineConfiguration {
  height?: string | number;
  isHide?: boolean;
  textProps?: ITextProps;
}

export interface ITopLineConfiguration extends ILineConfiguration {
  backgroundImage?: string;
  color?: string;
}

export const dropItemTypes = {
  '3': 3,
} as const;

export type TDropItemType = number | keyof typeof dropItemTypes;

export type TBottomLineConfiguration = ILineConfiguration;
